export const environments = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production'
};

export const getEnvironment = () => {
  switch (window.location.host) {
    case 'docs.veris-dev.loka.build':
      return environments.DEVELOPMENT;
    case 'docs.veris-staging.loka.build':
      return environments.STAGING;
    case 'docs.my.verishealth.com':
      return environments.PRODUCTION;
    default:
      return environments.DEVELOPMENT;
  }
};

export const getApiBaseUrl = () => {
  switch (getEnvironment()) {
    case environments.DEVELOPMENT:
      return 'https://verisdev-api.azure-api.net';
    case environments.STAGING:
      return 'https://verisstaging-api.azure-api.net';
    case environments.PRODUCTION:
      return 'https://verisprod-api.azure-api.net';
    default:
      return 'http://localhost:8000';
  }
};
